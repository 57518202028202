.tag{
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    border-radius: 36px;
    padding: 10px 15px;
    text-align: center;
    width: fit-content;
}