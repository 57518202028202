@import '../../style//varible.scss';

.main {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
}

.table {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  z-index: 10;
}
.mobile_view_table{
  display: none;
}
.td2 {
  cursor: pointer;
}

.search{
    width: 500px !important;
}
.header{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 38px;
color: #111111;
}
.pagination{
display: flex;
justify-content: space-between;
}

@media (max-width: $screen-md-max) {

  .pagination {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .mobile_view_table{
    display: flex;
    justify-items: center;
    justify-content: center;
    margin-top: 20px;
    

  }
  .table{
    display: none;
  }
}