.main {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btnLogin {
  width: 250px;
  height: 52px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}