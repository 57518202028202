.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #111111;
}
.content_wrapper {
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #666666;
  border-radius: 8px;
  border: 1px solid #ededed;
  padding: 20px 10px;
  margin-top: 2rem;
  height: 500px;
  overflow-y: scroll;
}
.content_wrapper::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.content_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content_wrapper::-webkit-scrollbar-thumb {
  background: #03abab;
}

/* Handle on hover */
.content_wrapper ::-webkit-scrollbar-thumb:hover {
  background: #03abab;
}

.content_wrapper::-webkit-scrollbar-button {
  height: 50px;
}
.button {
  width: 200px;
  height: 48px;
  color: white;
  /* Primary/Primary 200 */
  margin: 0 auto;
  background: #03abab;
  border-radius: 5px;
  margin-top:1.5rem ;
}

.agreeBtnWarn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
margin-top:1.5rem ;
  text-align: center;

  color: #03abab;
}
 