@import '../../style/varible';

.main {
  min-height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 29.5%, #F0FCFC 100%);
  position: relative;
  overflow: hidden;

  padding-left: 16px;
  padding-right: 16px;

  // &:before {
  //   background-image: url("../../statics/images/bg-left.png");
  //   left: 0;
  //   top: 100px;
  //   width: 210px;
  //   height: 375px;
  //   content: '';
  //   position: absolute;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-position: left;
  // }

  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  &:after {
    background-image: url("../../statics/images/bg-home.png");
    right: 0;
    top: -100px;
    width: 200px;
    height: 475px;
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    background-position-x: 100px;
  }

  :global(.container-content) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
  }
}


@media (min-width: $screen-sm-min) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: $screen-md-min) {
  .main {
    &:after {
      width: 400px;
      top: 0
    }
  }
}

@media (min-width: $screen-lg-min) {
  .main {
    &:after {
      top: 20%;
      width: 600px;
      height: 475px;
      background-position-x: right;
    }
  }
}