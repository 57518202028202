.main {
  background: #fbfbfb;
  border-top: 1px solid #ededed;
  padding: 20px 10px;
}
.table {
  width: 100%;
  margin-top: 10px;
}
.tr_td {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
  padding-bottom: 10px;
}
.td {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
}
.col1,
.col2,
.col3,
.col4 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.col2 {
  width: 200px !important;
}
.subcol1 {
  display: flex;
  gap: 2px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  align-items: center;
}
