$screen-xs: 480px;
$screen-xs-min: $screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1300px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: (
  $screen-sm-min - 1px
);
$screen-sm-max: (
  $screen-md-min - 1px
);
$screen-md-max: (
  $screen-lg-min - 1px
);
$screen-lg-max: (
  $screen-xl-min - 1px
);
$screen-xl-max: (
  $screen-xxl-min - 1px
);

$primary-color: #037C7C;
$primary-1: #13A1A1;
$primary-2: #03ABAB;

$text-color: #111111;
$text-color-secondary: #666666;