@import '../../style/varible';

.main {
  min-height: 100%;
  width: 100%;
  background: #F1F7F7;

  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  :global(.container-content) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
  }
}

@media (min-width: $screen-sm-min) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}
