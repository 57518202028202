.main{
display: flex;
flex-direction: column;
gap:1rem;
justify-items: center;
margin: 0 auto;
width: 770px;
}

.container {
  width: 770px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.wrapper {
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
}
.titleOne {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #111111;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 5px;
  }
.header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #111111;
}
.subText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  margin-top: 1rem;
}
.participate {
  background: #f1f7f7;
  border: 1px solid #e2f2f2;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  padding: 20px 10px;
  margin-top: 1rem;
}
.participateName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}
.participateHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #037c7c;
}
.dot {
  color: #037c7c;
  font-size: 20px;
}

.agreementFormat {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  border: 1px solid #ededed;
  margin-top: 1.5rem;
}
.agreementFormatHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  color: #037c7c;
}

.agreementFormatSection1 {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.section1_part1,
.section1_part2,
.section1_part3 {
  display: flex;
  gap: 2px;
}
.section1_part3 {
  margin-top: 1.5rem;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
}
.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}
.breaker {
  border: 1px solid #ededed;
  margin: 10px 0px;
}
.agreementFormatSection2 {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.section2_part1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-transform: uppercase;

  color: #666666;
  margin-top: 0.5rem;
}
.button {
  width: 224px;
  background: #03abab;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 0px;
  align-self: center;
  margin-top: 1.5rem;
}
.signaturePage {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 1rem;
}
.signatureHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}

.formSignature {
  border: 2px dashed #bcbcbc;
  border-radius: 8px;
  width: 100%;
  height: 180px;
}

.dateSection {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}
.dateHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}
.dateText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
}
.submit {
  width: 115px;
  background: #03abab;
  border-radius: 5px;
  color:#ffffff;
  padding: 15px 0px;
  margin: 0 auto;
}
