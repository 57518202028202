@import '../../../style/varible';

.wrapper {
  height: 50px;
  background: #E2F2F2;
  display: flex;
  align-items: center;
}

%cell {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #037C7C;

  // height: 100%;
}

.cell1 {
  width: 25%;
  @extend %cell;
 padding-left: 20px;
 
}

.cell2 {
  width: 15%;
  @extend %cell;
  padding-left: 20px;
 
}

.cell3 {
  width: 15%;
  @extend %cell;
  //padding-left: 20px;
 
}

.cell4 {
  width: 15%;
  @extend %cell;
 // padding-left: 20px;
  
}

.cell5 {
  width: 15%;
  @extend %cell;
 // padding-left: 20px;
}

.cell6 {
  width: 15%;
  @extend %cell;
  padding-left: 20px;
 // padding-right: 20px;
}
.cell7 {
  width: 14%;
  @extend %cell;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: $screen-md-max) {
  .wrapper {
    display: none;
  }
}
