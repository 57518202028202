@import "../../style/varible";
.main {
  margin-left: auto;
  margin-right: auto;
  z-index: 12;
  position: relative;
}

.content {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.title_text{
  font-size: 30px;
  @media (max-width: $screen-md-max) {
    font-size: 24px;
  }
}

.pageHeader {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  .title {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: flex;
    margin-top: 12px;
    @media (max-width: $screen-md-max) {
      font-size: 14px;
    }
  }
}

$color-waiting: #e2e2e2;

.steps {
  margin-top: 40px;
  z-index: 1;
  display: flex;
  justify-content: center;

  :global {
    .ant-steps-icon {
      font-size: 0px;
    }

    // .ant-steps-item-icon {
    //   width: 24px !important;
    //   height: 24px !important;
    //   display: flex !important;
    //   align-items: center;
    //   justify-content: center;
    //   z-index: 2;
    //   background-color: $color-waiting !important;
    //   position: relative !important;
    // }

    .ant-steps-item-icon {
      width: 24px !important;
      height: 24px !important;
      top: -3px;
      position: relative;
      background-color: unset !important;
      border: 2px solid $primary-color;
      position: relative !important;
      z-index: 2;

      &::after {
        width: 16px;
        height: 16px;
        left: 6px;
        top: 6px;
        content: "";
        position: absolute;
        background-color: $color-waiting;
        border-radius: 50%;
      }
    }

    .ant-steps-item-title {
      font-weight: 600;
      font-size: 16px;
      color: $color-waiting !important;
    }

    .ant-steps-item-tail {
      top: 7px !important;
      padding: 4px 0px !important;
      z-index: 1;

      &::after {
        height: 4px;
        background-color: $color-waiting !important;
      }
    }

    .ant-steps-item-process {
      .ant-steps-item-title {
        color: $primary-2 !important;
      }

      .ant-steps-item-icon {
        width: 24px !important;
        height: 24px !important;
        top: -1px;
        position: relative;
        background-color: unset !important;
        background-color: white !important;
        border: 2px solid $primary-2;

        &::after {
          width: 16px;
          height: 16px;
          left: 2px;
          top: 2px;
          content: "";
          position: absolute;
          background-color: $primary-2;
          border-radius: 50%;
        }
      }
    }
    .ant-steps-item-title {
      font-size: 12px !important;
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        width: 24px !important;
        height: 24px !important;
        top: -2px;
        position: relative;
        background-color: unset !important;
        border: none;
        position: relative !important;
        z-index: 2;

        &::after {
          width: 24px;
          height: 24px;
          left: 2px;
          top: 2px;
          content: "";
          position: absolute;
          background-color: $primary-2;
          border-radius: 50%;
        }
      }

      .ant-steps-item-tail {
        &::after {
          height: 4px;
          background-color: $primary-2 !important;
        }
      }

      .ant-steps-item-title {
        color: $primary-2 !important;
      }
    }
  }
}

.card {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

.navigation {
  margin-top: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

:global {
 
  @media (max-width: 450px) {
    .ant-steps {
      transform: scale(0.6) !important;
      margin-left: -50px;
    }   
  }
  
  @media(max-width: 350px) {
    .ant-steps {
      transform: scale(0.4) !important;
      margin-left: -65px;
    }
  }

  .sd-root-modern {
    background-color: white;
  }

  .sd-element {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .sd-input {
    background-color: white !important;
    border: 1px solid $color-waiting !important;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%) !important;
    border-radius: 5px !important;

    &:focus {
      border: 1px solid $primary-2 !important;
    }
  }

  .sd-element--complex:not(.sd-element--collapsed)
    > .sd-element__header--location-top {
    .sd-element__title .sv-string-viewer {
      font-size: 16px;
      font-weight: 600;
    }
  }

  div[data-key^="totalClaim"],
  div[data-key^="totalWithdrawn"],
  div[data-key^="total_celsius_earn_balances"],
  div[data-key^="total_celsius_custody_balances"],
  div[data-key^="total_celsius_witheld_balances"],
  div[data-key^="total_celsius_borrow_balances"],
  div[data-key^="account_preference_risk"],
  div[data-key^="total_celsius_total_deposits"] {
    margin-top: 14px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ededed;

    .sd-element__title .sv-string-viewer {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .sd-element__title {
    .sv-string-viewer {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .sd-page__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .sv-components-column {
    margin: 0px !important;
    max-width: unset !important;
  }

  .sd-body__page {
    // background: white !important;
    // padding: 50px !important;
    // border: 1px solid #f2f4f7 !important;
    // box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
    //   0px 4px 6px -2px rgb(16 24 40 / 3%) !important;
    // border-radius: 8px !important;
  }

  .sd-panel {
    padding: 50px !important;
    background: #ffffff !important;
    border: 1px solid #f2f4f7 !important;

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
    border-radius: 8px !important;

    .sd-panel__header {
      padding: 0 !important;
      margin-bottom: 32px;

      > .sd-title.sd-element__title.sd-panel__title {
        > span {
          font-family: "Inter" !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 24px !important;
          line-height: 32px !important;

          color: #111111;
        }
      }
    }

    .sd-panel__content {
      display: flex;
      flex-direction: column;
      gap: 32px 0;
    }
  }

  .sd-checkbox__decorator {
    background: white;
  }

  .sd-question__erbox--outside-question {
    margin: 0 !important;
    width: 100% !important;
  }

  .sd-container-modern {
    margin: 0 !important;
  }

  .sd-file {
    height: 150px !important;
    min-height: unset !important;
    margin-top: 10px;

    .sd-file__decorator {
      border-radius: 5px;
      // margin-top: 10px;
    }

    .sd-file__image-wrapper {
      img {
        background-color: unset;
      }
    }

    .sd-file__drag-area-placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }
  }

  .sd-expression {
    font-weight: 600 !important;
    font-size: 24px !important;
  }

  div[data-key="price-2"] {
    margin-top: 12px !important;
    margin-bottom: 12px !important;

    .sd-expression {
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }

  div[data-key^="celsius_statement"] {
    padding-bottom: 30px;
    border-bottom: 1px solid #ededed;
  }
  div[data-key^="option_celsius_tx_earn"],
  div[data-key^="option_celsius_tx_custody"] {
    // margin-top: 12px !important;
    // margin-bottom: 12px !important;

    .sv-title-actions {
      .sv-title-actions__title {
        .sv-string-viewer {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          color: #666666;
        }
      }
      .sd-action-bar.sd-action-title-bar.sd-action-bar--empty {
        display: none !important;
      }
    }

    .sd-selectbase {
      display: flex;
      gap: 24px;
    }
  }

  div[data-key^="preferenceRisk"],
  div[data-key^="celsius_transactions_manual"],
  div[data-key^="celsius_transactions_file"] {
    // padding-bottom: 30px;
    // border-bottom: 1px solid #ededed;
    .sd-question__header {
      padding-top: 0px !important;
      margin-bottom: 12px !important;
      .sv-string-viewer {
        font-weight: 500 !important;
        font-size: 14px !important;
      }
    }
  }
  div[data-key^="option_celsius_tx_earn"],
  div[data-key^="option_celsius_tx_custody"] {
    border-bottom: 1px solid #ededed;
  }

  div[data-key^="preferenceRisk"],
  div[data-key^="celsius_transactions_file"] {
    border-bottom: 1px solid #ededed;
    .sd-question__content {
      padding-bottom: 30px;
    }
  }

  div[data-key^="celsius_transactions_manual"],
  div[data-key^="celsius_tx_earn"],
  div[data-key^="celsius_tx_custody"] {
    .sd-panel__content {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .sd-paneldynamic__panel-footer {
      margin: 0;
      justify-content: flex-end !important;

      button {
        padding: 0;
        background-color: unset !important;
        color: #03abab;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .sd-row {
      display: FLEX;
      flex-direction: row;
      flex-wrap: inherit;
    }

    @media (max-width: 450px) {
      .sd-row {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    div[data-key^="Coin"],
    div[data-key^="Quantity"],
    div[data-key^="TxsDate"],
    div[data-key^="Value"],
    div[data-key^="Type"] {
      min-width: 150px !important;
      padding-left: 16px;
      // max-width: 100px !important;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    // div[data-key^='Coin'],
    // div[data-key^='Quantity'],
    // div[data-key^='Value'],
    // div[data-key^='Type'] {
    //   padding-left: 8px;
    // }
    div[data-key^="TxsDate"] {
      min-width: 220px !important;
      padding-left: var(--sd-base-padding);
    }
    // div[data-key^='Coin'],
    // div[data-key^='Type'] {
    //   padding-left: 8px;
    //   min-width: 140px !important;
    //   max-width: 140px !important;
    // }
  }

  .sd-paneldynamic__panel-wrapper:not(:first-child) {
    margin-top: 16px !important;
  }

  div[data-key^="celsius_address"] {
    position: relative;
  }

  .sd-root-modern {
    background-color: transparent;
  }

  .sd-action-bar {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .sd-navigation__next-btn {
    background: $primary-2;
    color: white;
  }

  .sd-paneldynamic__btn {
    outline: none;
    background-color: var(--primary-light, rgba(25, 179, 148, 0.1));
    cursor: pointer;
    opacity: 1;
  }

  div[data-key^="celsius_balances"] {
    > div {
      border-top: 1px solid #ededed;
      margin-top: 20px;
    }
  }

  div[data-key^="celsius_balances"],
  div[data-key^="celsius_balances"],
  div[data-key^="celsius_total_withdrawns"],
  div[data-key^="celsius_total_deposits"] {
    > div {
      border-bottom: 1px solid #ededed;
      border-radius: 0 !important;
    }

    .sd-question__header {
      padding-top: 20px !important;
    }

    .sd-question__content {
      .sd-paneldynamic__buttons-container {
        padding-bottom: 30px !important;
      }

      // padding-bottom: 30px !important;
    }

    div[data-key^="Coin"],
    div[data-key^="Quantity"],
    div[data-key^="price"] {
      min-width: 200px !important;
    }

    div[data-key^="Quantity"],
    div[data-key^="price"] {
      padding-left: 20px !important;
    }

    div[data-key^="Claim Value"] {
      .sd-question__header {
        padding-bottom: 8px;
      }
    }

    .sd-paneldynamic__panel-footer {
      margin: 0;
      margin-top: -40px;
      justify-content: flex-end !important;

      button {
        padding: 0;
        background-color: unset !important;
        color: #03abab;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  div[data-key^="celsius_relationship"] {
    margin-top: 14px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ededed;
  }

  .sd-checkbox {
    .sd-checkbox__decorator {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid #d0d5dd;
      box-shadow: unset;
    }

    .sd-item__control-label {
      font-weight: 500;
      font-size: 16px;
      line-height: unset;
      color: #666666;
    }
  }

  .sd-checkbox--checked {
    .sd-checkbox__decorator {
      background: #e2f2f2;
      border: 1px solid #03abab !important;

      svg {
        use {
          fill: #03abab;
        }
      }
    }
  }

  .sd-question__header--location-top {
    padding-bottom: 6px !important;
  }

  .sd-element--complex.sd-element--with-frame
    > .sd-element__header--location-top:after {
    content: none !important;
  }

  .sd-paneldynamic__separator {
    display: none !important;
  }

  .sd-paneldynamic__add-btn {
    background: #e2f2f2;
    border-radius: 5px;
    color: #03abab;
    font-weight: 600;
    font-size: 16px;
  }

  .sd-paneldynamic__buttons-container .sd-action-bar {
    margin-left: 0 !important;
  }

  .sd-paneldynamic {
    .sd-paneldynamic__placeholder {
      min-height: unset;
      align-items: start;
      text-align: start;

      .sv-string-viewer {
        white-space: unset;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
    }
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 26px;
  margin-top: 80px;

  h2 {
    margin: 0px;
    text-align: center;
  }
}

.result {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  padding: 8px;
}

.resultText {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #111111;
  max-width: 800px;
  width: 100%;
}

@media (min-width: 450px) {
  .ant-steps {
    width: 95px !important;
  }
}

div[data-key^="personal_info"] {
  :global(.sd-question__content) > :global(.sd-html) {
    padding: 30px;
    background: #f1f7f7;
    border-radius: 5px;

    &:after {
      background-image: url("../../statics/images/personal-bg-right.png");
      right: 0;
      bottom: 0;
      top: 0;
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
      width: 250px;
    }

    > div {
      > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: #111111;

        > span {
          color: #13a1a1;
          font-weight: 600;
        }
      }
    }

    > ul {
      list-style: inside;

      > li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: #111111;

        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }
  }
}

div[data-key^="schedule_basic_info"] {
  :global(.sd-question__content) > :global(.sd-html) {
    border-bottom: 1px solid #f2f4f7;

    > table {
      width: 100%;
      > thead {
        background-color: #e2f2f2;
        word-break: keep-all;

        > tr > th {
          padding: 12px;

          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;

          color: #111111;

          text-align: left;
        }
      }

      tbody {
        > tr {
          > td {
            padding: 12px;
            word-break: break-word;

            &:first-child {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;

              color: #111111;
            }

            &:nth-child(2) {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;

              color: #111111;
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}

div[data-key^="schedule_earn_account_info"],
div[data-key^="schedule_custody_account_info"],
div[data-key^="schedule_withheld_account_info"],
div[data-key^="schedule_borrow_account_info"] {
  :global(.sd-question__content) > :global(.sd-html) {
    padding: 0 20px;

    > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(auto, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      overflow: hidden;

      &:first-child {
        > div {
          &:first-child {
            background: #03abab;
            border-radius: 8px 8px 0 0;
            padding: 8px;

            > p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
              line-height: 24px;

              text-align: center;

              color: #ffffff;
              margin: 0;
            }
          }
        }
      }

      &:nth-child(2) {
        border-radius: 0px 0px 8px 8px;
        border: 2px solid #03abab;

        > div {
          border: 0.5px solid #ededed;
          padding: 16px 8px;

          > div {
            display: flex;
            align-items: center;

            > p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;

              color: #111111;
              margin: 0;
            }
            margin-bottom: 8px;
          }

          > p {
            &:nth-child(2) {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;

              color: #111111;
              margin: 0;
            }
          }
        }

        > p {
          grid-column: 1/5;
          margin: 30px;

          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;

          color: #111111;

          text-align: center;
        }
      }
    }
  }
}

div[data-key^="claim_amount_panel"],
div[data-key^="tx_earn_account_panel"],
div[data-key^="tx_custody_account_panel"] {
  :global(.sd-panel) {
    padding: 0 !important;
    background: #ffffff !important;
    border: none !important;

    box-shadow: none !important;
    border-radius: 0 !important;

    :global(.sd-panel__header) {
      padding: 0 !important;
      margin-bottom: 0;

      > :global(.sd-title.sd-element__title.sd-panel__title) {
        > span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;

          color: #111111;
        }
      }

      &::after {
        display: none;
      }
    }

    :global(.sd-panel__content) {
      display: flex;
      flex-direction: column;
      gap: 32px 0;
    }
  }
}

div[data-key^="celsius_earn_balances"],
div[data-key^="celsius_custody_balances"],
div[data-key^="celsius_witheld_balances"],
div[data-key^="celsius_borrow_balances"] {
  :global(.sd-question__content) > :global(.sd-html) {
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 30px;

    > div > div {
      &:first-child {
        > p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          color: #111111;
        }
      }

      &:nth-child(2) {
        > table {
          width: 100%;

          > thead > tr > th {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            color: #111111;
            text-align: left;
          }

          > tbody > tr > td {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #111111;

            padding: 12px;

            border-bottom: 1px solid #ededed;

            > div {
              display: flex;
            }
          }
        }
      }
      &:nth-child(3) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        > p {
          &:first-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            color: #111111;
          }

          &:last-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;

            color: #111111;
          }
        }
      }
      &:nth-child(4) {
        > button {
          width: 100%;
          background: #e2f2f2;
          border-radius: 5px;
          text-align: center;
          height: 48px;

          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          color: #03abab;
        }
      }
    }
  }
}

div[data-key^="celsius_tx_earn"],
div[data-key^="celsius_tx_custody"] {
  :global(.sd-question__content) > :global(.sd-html) {
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 30px;

    > div > div {
      &:first-child {
        > p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          color: #111111;
        }
      }

      &:nth-child(2) {
        > table {
          width: 100%;

          > thead > tr > th {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            color: #111111;
            text-align: left;
          }

          > tbody > tr {
            > td {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;

              color: #111111;
              padding: 12px;

              border-bottom: 1px solid #ededed;

              > div {
                display: flex;
              }
            }
          }
        }
      }

      &:nth-child(3) {
        margin-top: 25px;

        > button {
          width: 100%;
          background: #e2f2f2;
          border-radius: 5px;
          text-align: center;
          height: 48px;

          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          color: #03abab;
        }
      }

      &:nth-child(4) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        > p {
          &:first-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            color: #111111;
          }

          &:last-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;

            color: #111111;
          }
        }
      }
    }
  }
}
div[data-key^="preview_earn_account_info"],
div[data-key^="preview_custody_account_info"],
div[data-key^="preview_withheld_account_info"],
div[data-key^="preview_borrow_account_info"],
div[data-key^="preview_celsius_tx_earn"],
div[data-key^="preview_celsius_tx_custody"] {
  :global(.sd-question__content) > :global(.sd-html) {
    > div {
      &:first-child {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        overflow: hidden;

        > div {
          &:first-child {
            background: #03abab;
            border-radius: 8px 8px 0 0;
            padding: 8px;

            > p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
              line-height: 24px;

              text-align: center;

              color: #ffffff;
              margin: 0;
            }
          }
        }
      }

      &:nth-child(2) {
        border-radius: 0px 0px 8px 8px;
        border: 2px solid #03abab;
        overflow: hidden;

        > table {
          width: 100%;
          table-layout: fixed;

          > thead > tr > th {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            color: #111111;
            text-align: left;

            padding: 12px;
          }

          > tbody > tr {
            > td {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;

              color: #111111;
              padding: 12px;

              border-bottom: 1px solid #ededed;

              > div {
                display: flex;
              }

              &[colspan="4"] {
                > p {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  color: #111111;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

div[data-key^="preview_personal_info"] {
  :global(.sd-question__content) > :global(.sd-html) {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid #ededed;

      > span {
        &:first-child {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          color: #111111;
        }

        &:nth-child(2) {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          color: #111111;
        }
      }
    }
  }
}

div[data-key^="preview_claim_info"],
div[data-key^="preview_poc_info"] {
  :global(.sd-question__content) > :global(.sd-html) {
    display: flex;
    flex-direction: column;
    gap: 30px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;

        > span {
          &:first-child {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            color: #666666;
          }

          &:nth-child(2) {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;

            color: #111111;
          }
        }
      }
    }
  }
}

.modal-container {
  :global(.ant-modal-content) {
    padding: 30px;
  }
}

.form-container {
  z-index: 1;

  > :global(.ant-form-item) {
    margin-bottom: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    > h2 {
      margin: 0;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }

    :global(.ant-input),
    :global(.ant-input-number) {
      height: 48px;
      padding-left: 16px;
      padding-right: 16px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
      width: 100%;

      :global(.ant-input-number-input-wrap) {
        height: 100%;

        :global(.ant-input-number-input) {
          height: 100%;
          padding: 0;
        }
      }
    }

    :global(.ant-select) {
      height: 48px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;

      :global(.ant-select-selector) {
        height: 100%;
      }

      :global(.ant-select-selection-item) {
        line-height: 48px;
      }
    }

    :global(.ant-picker) {
      height: 48px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 5px;
      width: 100%;
    }

    button {
      width: 100%;
      height: 48px;
      border-radius: 5px;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      box-shadow: none;

      &:first-of-type {
        color: #ffffff;
        background: #03abab;
      }

      &:nth-of-type(2) {
        margin-top: 10px;
        border: 1px solid #e2e2e2;
        color: #111111;
      }
    }
  }
}

:global(.ant-picker-ok) {
  > button {
    background-color: #037c7c;
  }
}

.sd-panel__required-text {
  color: red !important;
}
