@import '../../../style/varible';

.table {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  z-index: 10;
}
.mobile_view_table{
  display: none;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: $screen-md-max) {

  .pagination {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .mobile_view_table{
    display: flex;
    justify-items: center;
    justify-content: center;
    margin-top: 20px;
    

  }
  .table{
    display: none;
  }
}