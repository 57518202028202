@import '../../style/varible';

.header {
  height: 70px;
  width: 100%;
  padding: 0 12px;
}

.headerWrappper {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 90% !important;
}

%menuActive {
  color: $primary-2;
  position: relative;

  &::after {
    position: absolute;
    bottom: -2px;
    content: '';
    height: 2px;
    background: $primary-2;
    width: 100%;
    left: 0;
  }
}
:global {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item-active {
      color: #13A1A1!important;
      background-color: #e2f2f2!important;
    }
  }
}

.menu {
  height: 100%;
  > ul {
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;
    list-style: none;

    > li {
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;

      > a {
        color: $text-color;

        &:hover {
          @extend %menuActive;
        }
      }

      .active {
        @extend %menuActive;
      }
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;
  height: 100%;

  > ul {
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;
    list-style: none;

    > li {
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;

      > a {
        color: $text-color;

        &:hover {
          @extend %menuActive;
        }
      }

      .active {
        @extend %menuActive;
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .menu {
    display: none;
  }

  .drawer {
    display: block;
  }

  .hamburger {
    display: block;
  }
}
