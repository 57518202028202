@import '../../../style/varible';

.footer {
  margin-left: auto;
  margin-right: auto;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  width: 100%;
  height: 86px;

  :global(.ant-typography-secondary) {
    color: #666666;
  }
}

.row {
  display: flex;
  gap: 6px;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */


  /* Grey/Grey 400 */

  color: #666666;

  :global(.ant-typography) {
    font-size: 16px;
  }
}

@media (min-width: $screen-lg-min) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
  }
}