.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #111111;
  }
  .modalContent {
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #666666;
    border-radius: 8px;
    border: 1px solid #ededed;
    padding: 20px 10px;
    margin-top: 2rem;
    height: 500px;
    overflow-y: scroll;
  }
  .modalContent::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .modalContent::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .modalContent::-webkit-scrollbar-thumb {
    background: #03abab;
  }
  
  /* Handle on hover */
  .modalContent ::-webkit-scrollbar-thumb:hover {
    background: #03abab;
  }
  
  .modalContent::-webkit-scrollbar-button {
    height: 50px;
  }
  .button {
    width: 200px;
    height: 48px;
    color: white;
    /* Primary/Primary 200 */
    margin: 0 auto;
    background: #03abab;
    border-radius: 5px;
    margin-top:1.5rem ;
  }
  
  .agreeBtnWarn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */
  margin-top:1.5rem ;
    text-align: center;
  
    color: #03abab;
  }
   

.masterAgreement {
    position: relative;
    line-height: 28px;
    font-weight: 600;
  }
  .span {
    color: #111;
  }
  .draftNo {
    margin: 0;
  }
  .blankLine {
    margin: 0;
    color: #111;
  }
  .draftNoContainer {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .frameWrapper1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #666;
  }
  .frameChild {
    position: relative;
    border-top: 1px solid #ededed;
    box-sizing: border-box;
    width: 623px;
    height: 1px;
  }
  .party1Wrapper {
    width: 622px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .celsius0Spv {
    position: relative;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .frameWrapper2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #666;
  }
  .applebySeychellesOfficeContainer,
  .masterAgreementRelating {
    position: relative;
    line-height: 20px;
    font-weight: 600;
  }
  .applebySeychellesOfficeContainer {
    text-transform: uppercase;
  }
  .suite202SecondContainer {
    position: relative;
    line-height: 16px;
    color: #666;
  }
  .applebySeychellesOfficeParent {
    gap: 4px;
    font-size: 12px;
    color: #ff4646;
  }
  .applebySeychellesOfficeParent,
  .frameDiv,
  .lineParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .lineParent {
    gap: 40px;
    font-size: 20px;
  }
  .frameDiv {
    gap: 32px;
  }
  .contents {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #037c7c;
  }
  .clauseParent {
    width: 625px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    color: #111;
  }
  .div {
    display: inline-block;
    width: 20px;
    flex-shrink: 0;
  }
  .definitionsAndInterpretation,
  .div {
    position: relative;
    line-height: 20px;
  }
  .parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  .div1 {
    position: relative;
    line-height: 20px;
    text-align: right;
    display: inline-block;
    width: 40px;
    flex-shrink: 0;
  }
  .contentsParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 13px;
    color: #666;
  }
  .frameGroup {
    gap: 24px;
  }
  .frameContainer,
  .frameGroup,
  .frameWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameWrapper {
    font-size: 18px;
    color: #037c7c;
    font-family: Inter;
  }
  .thisAgreement {
    font-weight: 600;
  }
  .isDated {
    color: #666;
  }
  .div18,
  .parties,
  .thisAgreementIsContainer {
    position: relative;
    line-height: 24px;
  }
  .div18,
  .parties {
    text-transform: uppercase;
    color: #666;
  }
  .parties {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #037c7c;
    display: inline-block;
    width: 622px;
  }
  .nameOfRecipient {
    margin-bottom: 0;
  }
  .nameOfRecipientAResident {
    margin: 0;
    padding-left: 17px;
  }
  .nameOfRecipientContainer {
    position: relative;
    font-size: inherit;
    line-height: 24px;
    font-family: inherit;
    color: #666;
    display: inline-block;
    width: 622px;
  }
  .frameWrapper3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 13px;
    color: #111;
    font-family: Inter;
  }
  .preamble {
    position: relative;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    width: 622px;
  }
  .preambleParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: 18px;
    color: #037c7c;
    font-family: Inter;
  }
  .definitionsAndInterpretation1 {
    margin: 0;
    padding-left: 24px;
  }
  .definitionsAndInterpretationParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    color: #037c7c;
  }
  .definitionsAndInterpretation2 {
    margin: 0;
    padding-left: 19px;
  }
  .assignmentOfClaim {
    font-weight: 600;
    font-family: Inter;
    color: #111;
  }
  .atheUnitedStates {
    font-family: Inter;
    color: #666;
  }
  .restrictedJurisdictionAt {
    margin: 0;
    padding-left: 17px;
    color: #111;
  }
  .inThisAgreementContainer {
    font-family: Inter;
  }
  .inThisAgreementContainer,
  .referencesInThisContainer {
    position: relative;
    line-height: 24px;
    display: inline-block;
    width: 622px;
  }
  .definitionsAndInterpretationGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  .acceptanceOfAgreementContainer {
    position: relative;
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 600;
    color: #037c7c;
    display: inline-block;
    width: 622px;
  }
  .a {
    width: 40px;
  }
  .a,
  .aa,
  .itIsTheContainer,
  .m {
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
  }
  .itIsTheContainer {
    font-size: 13px;
    font-family: Inter;
    width: 569px;
  }
  .aa,
  .m {
    letter-spacing: -0.02em;
    width: 40px;
  }
  .aa {
    width: 48px;
  }
  .aaParent {
    width: 625px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .inEachCase,
  .miscellaneous1,
  .thePartiesAgreeContainer {
    position: relative;
    line-height: 24px;
    display: inline-block;
    width: 622px;
  }
  .inEachCase {
    font-size: 13px;
    font-family: Inter;
  }
  .miscellaneous1,
  .thePartiesAgreeContainer {
    color: #666;
  }
  .miscellaneous1 {
    line-height: 28px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: inherit;
    color: #037c7c;
  }
  .frameWrapper5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: Inter;
  }
  .signatories,
  .thisAgreementHas {
    position: relative;
    line-height: 28px;
    display: inline-block;
    width: 622px;
  }
  .signatories {
    font-weight: 600;
  }
  .thisAgreementHas {
    font-size: 14px;
    color: #666;
  }
  .signatoriesParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .signedForAndContainer {
    position: relative;
    line-height: 28px;
    display: inline-block;
    width: 622px;
  }
  .frameInner {
    position: relative;
    border-top: 1px solid #ededed;
    box-sizing: border-box;
    width: 201px;
    height: 1px;
  }
  .frameParent14 {
    flex-direction: column;
    gap: 40px;
    font-size: 14px;
    color: #111;
  }
  .assignmentOfClaimAgreement,
  .frameParent,
  .frameParent14 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .frameParent {
    flex-direction: column;
    gap: 40px;
  }
  .assignmentOfClaimAgreement {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow: hidden;
    flex-direction: row;
    padding: 40px 24px;
  }
  .assignmentOfClaimAgreementParent {
    position: relative;
    width: 100%;
    height: 17812px;
    text-align: left;
    font-size: inherit;
    color: #666;
    font-family: inherit;
  }
  
  .header{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;

color: #111111
  }
  .disabledBtn{
    background: #E2E2E2;
    color:black
  }