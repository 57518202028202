@import '../../style/varible';

.main {
  min-height: 100%;
  width: 100%;
  background: #f1f7f7;

  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    z-index: 1;
  }

  :global(.container-content) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
  }

  &:before {
    background-image: url('../../statics/images/bg-left.png');
    left: 0;
    top: 100px;
    width: 210px;
    height: 375px;
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
  }

  &:after {
    background-image: url('../../statics/images/bg-right.png');
    right: 0;
    bottom: 20px;
    width: 210px;
    height: 375px;
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
}

@media (min-width: $screen-sm-min) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}
