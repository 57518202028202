.main{
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    width:343px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    z-index: 50;
}

.main_two{
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
}