@import '../../style/varible';

.main {
  margin-left: auto;
  margin-right: auto;
  z-index: 12;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #111111;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 5px;
}

.content {
  width: 100%;
  max-width: 770px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 50px;
}

.contentTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #111111;
}

.contentSubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #666666;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.contentBasic {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;

  background: #f1f7f7;

  border: 1px solid #e2f2f2;
  border-radius: 8px;

  > div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Grey/Grey 500 */

    color: #111111;

    > span {
      color: #037c7c;
    }
  }
}

.contentInfo {
  display: flex;
  flex-direction: column;
  background: #f1f7f7;
  border: 1px solid #e2f2f2;
  border-radius: 8px;
  margin-top: 24px;
  max-height: 460px;
  overflow-y: auto;

  ul {
    margin: 0;
    padding: 0;
    padding-left: 10px;
    display: flex;
    gap: 12px;
    flex-direction: column;

    li {
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #111111;
        margin-left: 5px;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    background: white;
    color: #000;
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    font-family: 'Inter';
    font-style: normal;

    p {
      margin-bottom: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 10px;
      display: flex;
      gap: 12px;
      flex-direction: column;

      li {
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #111111;
          margin-left: 5px;
        }
      }
    }

    .gray {
      color: #666666;
    }

    > hr {
      border-color: #ededed;
    }

    .basic-block {
      display: flex;
      flex-direction: column;
      gap:5px;
      // > div {
      //   flex: 1;
      // }
    }

    table {
      > tr > td:not(:first-child) {
        padding-left: 32px;
      }
    }

    .claim-rules {
      // text-align: justify;

      > ul {
        list-style: initial;
      }
    }

    .signature-block {
      display: flex;
  flex-direction: column;
  align-items: end;
      > div {
        flex: 1;
      }
    }

    .center {
      text-align: center;
    }

    .nowrap {
      white-space: nowrap;
    }

    .debtors-block {
      display: flex;
      gap: 32px;

      > div {
        flex: 1;
      }
    }

    .security-block {
      display: flex;
      justify-content: space-between;
   margin-top:1rem;
      > div {
       // flex: 1;
      }
    }

    .transactions-block {
      height: 100%;
      border-radius: 4px;
      border: 1px solid #ededed;

      > table {
        width: 100%;
        border-radius: 4px;
        overflow: hidden;

        th {
          background-color: #13a1a1;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #ffffff;
          height: 32px;
          text-align: left;
          padding: 0 12px;
        }

        td {
          height: 52px;
          padding: 12px;
        }
      }
    }

    .break-before {
      page-break-before: always;
    }

    .cyan {
      color: #037c7c;
    }
  }
}

.formItem {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.formLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}

.formLabelRequired {
  margin-left: 5px;
  color: red;
}

.formSignature {
  border: 2px dashed #bcbcbc;
  border-radius: 8px;
  width: 100%;
  height: 180px;
}

.formValue {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
}

.formInput {
  width: 100%;
  height: 48px;
  padding: 12px 16px;

  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-size: 18px;
}

.formSelect {
  width: 100%;
}

.formSelect input {
  height: 46px !important;
  padding: 12px 16px !important;
}

.formSelect :global(.ant-select-selector) {
  height: 48px !important;
}

.formSelect :global(.ant-select-selection-item) {
  line-height: 46px !important;
}

.formSubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #666666;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.buttons {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  height: 48px;
  background: #03abab;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border: none;

  cursor: pointer;

  &:hover {
    // color:rgb(240, 240, 240);
    color: #ffffff !important;
    background: #029797;
  }

  &:disabled {
    // color:rgb(240, 240, 240);
    color: #ffffff;
    background: #029797;
    opacity: 0.5;
  }
}

.back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  max-width: 162px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111111;

  cursor: pointer;

  &:hover {
    // color:rgb(240, 240, 240);
    color: #111111 !important;
    background: #ffffff;
  }

  &:disabled {
    // color:rgb(240, 240, 240);
    color: #ffffff;
    background: #029797;
    opacity: 0.5;
  }
}

.result {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  min-height: 500px;
  padding: 8px;
}

.resultText {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #111111;
  max-width: 800px;
  width: 100%;
}

.step3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 69px;

    .fee_payment {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      color: #13a2a2;
    }

    .claim_amount {
      display: flex;
      margin-top: 12px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #111111;
    }
  }

  .cards {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .in_claim {
      background: #ffffff;
      /* Gray/100 */

      border: 1px solid #f2f4f7;
      /* Shadow/lg */

      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 8px;
      width: 376px;
      height: 438px;

      .in_claim_header {
        width: 376px;
        height: 114px;
        left: 0px;
        top: 0px;
        background: #ededed;
        border-radius: 8px 8px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        /* identical to box height, or 133% */

        /* Grey/Grey 500 */

        color: #111111;
      }

      .in_claim_body {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .key {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #111111;
          }

          .value {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: #db9712;
          }
        }

        .line_break {
          width: 328px;
          height: 0px;
          border: 1px solid #ededed;
          flex: none;
          flex-grow: 0;
        }

        .pay_claim {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          gap: 8px;

          width: 328px;
          height: 48px;
          left: 24px;
          top: 366px;

          /* Grey/White */

          background: #ffffff;
          /* Grey/Grey 200 */

          border: 1px solid #e2e2e2;
          border-radius: 5px;

          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          /* Grey/Grey 500 */

          color: #111111;
        }
      }
    }

    .in_flex {
      margin-left: 18px;
      width: 376px;
      height: 438px;
      background: #ffffff;
      /* Gray/100 */

      border: 1px solid #f2f4f7;
      /* Shadow/lg */

      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 8px;

      .in_flex_header {
        width: 376px;
        height: 114px;
        left: 0px;
        top: 0px;
        background: #13a1a1;
        border-radius: 8px 8px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
      }

      .in_flex_body {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .key {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #111111;
          }

          .value {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: #13a2a2;
          }
        }

        .line_break {
          width: 328px;
          height: 0px;
          border: 1px solid #ededed;
          flex: none;
          flex-grow: 0;
        }

        .pay_flex {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 30px;
          gap: 8px;

          width: 328px;
          height: 48px;
          left: 418px;
          top: 366px;

          /* Primary/Primary 200 */

          background: #03abab;
          border-radius: 5px;

          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          /* Grey/White */

          color: #ffffff;
        }
      }
    }
  }
}

.modal_step3 {
  // left: 50%;
  // top: 50%;

  /* White */

  background: #fff;
  /* Gray/100 */

  border: 1px solid #f2f4f7;
  /* Shadow/lg */

  // box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
  //   0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 0;

  :global(.ant-modal-content) {
    box-shadow: none;

    :global(.ant-modal-body) {
      padding: 6px;

      :global(.title) {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;

        text-align: center;

        color: #111111;
        padding: 0 20px;
        > span {
          color: #03abab;
        }
      }

      :global(.button-container) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px 0;
        margin-top: 32px;

        > button {
          width: 100%;
          border-radius: 5px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          padding: 12px;
          height: 100%;

          &:first-child {
            background: #03abab;

            color: #ffffff;
          }

          &:nth-child(2) {
            color: #111111;
          }
        }
      }
    }
  }
}

// @media (min-width: $screen-lg-min) {
//   .title {
//     font-size: 72px;
//     line-height: 90px;

//     margin-top: 93px;
//   }

//   .subTitle {
//     font-size: 20px;
//     line-height: 30px;
//   }

//   .touch {
//     font-size: 20px;
//     line-height: 30px;
//   }

//   .inputWrapper {
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;

//     input {
//       max-width: 300px !important;
//       padding-top: 12 !important;
//       padding-bottom: 12 !important;
//     }
//   }
// }

.flex-description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 8.5px;
  line-height: 20px;
  color: #03abab;
  text-align: center;

  > span {
    color: #f04438;
  }
}

.pay_flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;

  & > * {
    flex: 1 1 50%;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &:last-child:not(:disabled) {
      background: #03abab;
      color: #ffffff;
    }
  }
}