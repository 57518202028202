@import "../../../style/varible";

.wrapper {
  display: flex;
  flex-direction: column;
}

.row {
  height: 60px;
  background: white;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0;

  > div {
    label {
      display: none;
    }
  }
}

.section_selected {
  background: #13a2a2 !important;
  border: 1px solid #ededed !important;
  border-radius: 8px 8px 0p !important;
  color:white !important;
}
%cell {
  font-size: 14px;
  line-height: 20px;
}

.cell1 {
  width: 25%;
  @extend %cell;
  padding-left: 20px;
  font-weight: 600;
  cursor: pointer;
}

.cell2 {
  width: 15%;
  @extend %cell;
  padding-left: 20px;
  display: flex;
}

.cell3 {
  width: 15%;
  @extend %cell;
  padding-left: 20px;
}

.cell4 {
  width: 15%;
  @extend %cell;
  padding-left: 20px;
}

.cell5 {
  width: 15%;
  @extend %cell;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  
}

.cell6 {
  width: 15%;
  @extend %cell;
  padding-left: 3rem;
}

.cell7 {
  width: 14%;
  @extend %cell;
  display: flex;
  justify-content: center;

  img {
    cursor: pointer;
  }

  button {
    display: none;
  }
}

.claimInfo {
  background: #fbfbfb;
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  box-shadow: 0px 27px 36px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  .heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
}

.subTable {
  font-size: 12px;
  line-height: 18px;
  display: grid;

  grid-template-columns: auto auto auto auto auto auto auto;

  .subTableHeader {
    font-weight: 600;
    color: #111111;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ededed;
  }

  .subTableCell {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #666666;
  }
}



.subTable1 {
  font-size: 12px;
  line-height: 18px;
  display: grid;
  max-width: 213px;
  grid-template-columns: auto auto;
  

  .subTableHeader {
    font-weight: 600;
    color: #111111;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ededed;
   
  }

  .subTableCell {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #666666;
  }
}

.claimAmount {
  background: #fbfbfb;
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.amountTable {
  grid-template-columns: auto auto auto auto auto;
  width: 70%;
  max-width: 900px !important;
}
.info {
  display: flex;
  gap: 3px;
  align-items: center;
}

@media (max-width: $screen-md-max) {
  .row {
    flex-direction: column;
    height: auto;
    padding: 12px;

    > div {
      width: 100%;
      padding-left: 0;
      padding-top: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #ededed;
      display: flex;
      flex-direction: column;
      gap: 6px;

      > label {
        display: block;
        color: #666666;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > a {
          text-decoration-line: underline;
          color: #03abab;
          font-weight: 400;
        }
      }

      > span {
        display: flex;
        align-items: center;
      }
    }

    // .cell1 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell2 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell3 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell4 {
    //   border-bottom: 1px solid #EDEDED;
    //   padding-bottom: 14px;
    // }

    // .cell5 {
    // border-bottom: none;
    // padding-bottom: 14px;
    // }

    .cell6 {
      border-bottom: none;

      > img {
        display: none;
      }

      > button {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.each_class {
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  gap: 3px;
  color: #666666;
}
.cross {
  color: #f04438;
  font-size: 20px;
}
.sign {
  color: #31b43e;
  font-size: 20px;
}
