@import "varible";

* {
  font-family: "Inter", sans-serif !important;
}

body,
#root {
  height: 100%;
}

@mixin make-container($minWidth, $breakpoint) {
  @media (min-width: $minWidth) {
    .container {
      max-width: $breakpoint;
    }
  }
}

@include make-container($screen-xs-min, $screen-xs);
@include make-container($screen-sm-min, $screen-sm);
@include make-container($screen-md-min, $screen-md);
@include make-container($screen-lg-min, $screen-lg);
@include make-container($screen-xl-min, $screen-xl);
@include make-container($screen-xxl-min, $screen-xxl);

:root {
  --base-unit: 7px;
}

.sd-btn:not(:disabled):hover,
.sd-btn:not(:disabled):focus {
  //box-shadow: none !important;
}
.ant-menu-light {
  background: transparent !important;
  border-bottom: none !important;
}
.ant-menu-title-content {
  font-size: 14px;
  font-weight: 600;
}

.sd-btn {
  border: none;
  color: white;
  padding: 10px 32px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.sd-btn {
  background-color: transparent !important;
  color: black;
  border: 1px solid #037c7c !important;
}

.sd-btn:hover {
  background-color: #037c7c !important;
  color: white;
  border: 1px solid #037c7c !important;
}

.ant-form-item-required {
  &::before {
    right: -15px;
    top: -0px;
    position: absolute;
  }
}
.multi-line-text {
  .ant-form-item-required {
    &::before {
      right: 22px;
      top: 22px;
      position: absolute;
    }
  }
}

.modal-limit {
  width: 350px;
  .ant-modal-content {
    padding: 0px;
    .ant-modal-header {
      padding: 22px 0px;
      background: #13a1a1;
    }
    .title-txt {
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      /* identical to box height, or 100% */

      /* Grey/White */

      color: #ffffff;
    }
    .txt-desc {
      padding: 0px 15px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      display: flex;
      align-items: center;

      /* Grey/Grey 500 */

      color: #111111;
    }
  }
  .button {
    width: 180px;
    height: 48px;
    color: white;
    /* Primary/Primary 200 */
    margin: 0 auto;
    background: #03abab;
    border-radius: 5px;
    margin: 17px;
  }
}

.modal-ftx-code {
  width: 600px;
  .ant-modal-content {
    padding: 0px;
    .ant-modal-header {
      padding: 22px 0px;
      background: #13a1a1;
    }
    .title-txt {
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      /* identical to box height, or 100% */

      /* Grey/White */

      color: #ffffff;
    }
    .txt-desc {
      padding: 0px 25px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      display: flex;
      align-items: center;

      /* Grey/Grey 500 */

      color: #111111;
    }
    .bold-txt {
      font-weight: 600;
    }
    .mail-txt {
      color: #03ABAB;
    }
  }
  .button {
    width: 180px;
    height: 48px;
    color: white;
    /* Primary/Primary 200 */
    margin: 0 auto;
    background: #03abab;
    border-radius: 5px;
    margin: 17px;
  }
}
