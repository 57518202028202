.main {
  width: 100%;
  align-self: center;
}

.table {
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  border-spacing: 0 16px;
  border-collapse: separate;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
    background-color: #e2f2f2;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #03abab;

    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
.table:hover {
  &::-webkit-scrollbar-thumb {
    background-color: #037c7c;
  }
}
.thead {
  width: 100%;
}
.tr1 {
  width: 100%;
  padding: 10px 10px;
}
.tr_th {
  font-family: "Inter";
  background: #e2f2f2;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #037c7c;
  padding:10px 10px;
  text-align: left;
}
.tbody {
  width: 100%;
}
.tr2 {
  border: 1px solid #ededed;
  box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}
.tr_td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding: 10px 10px;
}

td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
td:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.case {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.td2 {
  cursor: pointer;
}
.parent_div {
  display: flex;
  gap: 2px;
  justify-items: center;
}
.claim {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.copy {
  font-size: 20px;
  cursor: pointer;
  color: #03abab !important;
}
.arrow {
  font-size: 30px;
  cursor: pointer;
}
.claim_status_div {
  background: #eae9ff;
  border-radius: 36px;
  padding: 4px 2px;
  text-align: center;
}
.claim_status {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #6558ff;
}
.action {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 10px 2px;
  text-align: center;
}
.action_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #03abab;
}

.section_selected {
  background: #13a2a2 !important;
  border: 1px solid #ededed !important;
  border-radius: 8px 8px 0p !important;
  color: white !important;
}
