.wrapper {
  display: flex;
  width: 850px;
  flex-direction: column;
  background-color: #ffffff;
}
.header {
  background: #13a1a1;
  border: 1px solid #ededed;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  padding: 15px 20px;
}
.header_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}
.balance {
  display: flex;
  flex-direction: column;
}
.subHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 1rem;
  margin-left: 1rem;
  color: #111111;
}
.balanceSection {
  display: flex;
  flex-direction: column;
  padding: 1px 10px;
}
.menu {
  display: flex;

  margin-top: 2rem;
}

.table_balance {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
  border: 1px solid #03abab;
  border-radius: 0px 0px 8px 8px;
  > div {
    padding: 24px 16px;
    border: 1px solid #ededed!important;
 
    > div {
      display: flex;
      align-items: center;

      > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        color: #111111;
        margin: 0;
      }
      margin-bottom: 8px;
    }

    > p {
      &:nth-child(2) {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #111111;
        margin: 0;
      }
    }
  }

  > p {
    grid-column: 1/5;
    margin: 30px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #111111;

    text-align: center;
  }
}

.menuItems1,
.menuItems2,
.menuItems3,
.menuItems4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #666666;
  padding: 8px 0px;
  width: 25%;
  border: 1px solid #ededed;
  cursor: pointer;
}
.menuItems1 {
  border-radius: 10px 0 0px 0px;
}
.menuItems4 {
  border-radius: 0px 10px 0px 0px;
}
.selectedMenu {
  background: #03abab;
  color: white;
}
.menuSection {
  display: flex;
  flex-direction: column;
  border: 1px solid #03abab;
  border-radius: 0px 0px 8px 8px;
}
.menuCol {
  display: flex;
  padding: 20px 10px;
}
.colItem1,
.cell1 {
  width: 25%;
}
.colItem2,
.cell2 {
  width: 25%;
}
.colItem3,
.cell3 {
  width: 25%;
}
.colItem4,
.cell4 {
  width: 25%;
}
.cell5 {
  width: 160px;
}
.colItem1,
.colItem2,
.colItem3,
.colItem4,
.colItem5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
  text-align: left;
}
.menuRow {
  display: flex;
  padding: 17px 16px;
}
.menuRow:nth-child(2n + 1) {
  border-bottom: 1px solid #ededed;
}
.rowWrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.fileName {
  text-transform: capitalize;
}

.noDataTxt {
  text-align: center;
  padding: 17px 16px;
}
.cell1,
.cell2,
.cell3,
.cell4,
.cell5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #111111;
}
.cell1 {
  display: flex;
  text-align: left;
  gap: 5px;
}
.cell2 {
  text-align: left;
}

.colItem2_1,
.cell2_1 {
  width: 25%;
}
.colItem2_2,
.cell2_2 {
  width: 25%;
}
.colItem2_3,
.cell3_1 {
  width: 25%;
}
.colItem2_4,
.cell4_1 {
  width: 25%;
}
.colItem2_1,
.colItem2_2,
.colItem2_3,
.colItem2_4,
.colItem2_5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
  text-align: left;
}
.cell2_1,
.cell2_2,
.cell3_1,
.cell4_1,
.cell5_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #111111;
}
.cell3_1 {
  display: flex;
  gap: 3px;
}
.pdfFile {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 17px 16px;
  border-top: 1px solid #ededed;
}
.fileHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #111111;
  display: flex;
  justify-content: space-between;
}

.pdfContent {
  display: flex;
  justify-content: space-between;
}
.pdfAction {
  display: flex;
  gap: 1rem;
}
.pdfSelect {
}
.actionBtn {
  display: flex;
  gap: 3px;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}
.actionText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #03abab;
}
.download {
  font-size: 1.5rem;
  cursor: pointer;
  color: #03abab;
}
.pdfStatus {
  width: 200px;
  text-align: left;
}
.accountHeader,
.cprHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-top: 1rem;
}
.accountStmt {
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 16px 0px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.accountStmtAction {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px 10px;
}
.cprList {
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  padding: 10px 10px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.cprItem {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #666666;

  padding: 10px 0px;
}
.cprItem:nth-child(2n + 1) {
  border-bottom: 1px solid #ededed;
}
.cprItemDetails {
  display: flex;
  justify-content: space-between;
}
.cprItemDetails1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
}
.cprItemDetails2 {
  display: flex;
  gap: 10px;
}
.icon {
  color: #03abab;
  font-size: 20px;
}
.editIcon {
  color: #03abab;
  font-size: 20px;
}
.cprEntry {
  display: flex;
  justify-content: space-between;
}
.cprInput {
  width: 678px;
  height: 48px;
  background: #ffffff;

  border: 1px solid #e2e2e2;
  padding: 0px 10px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
}

.cprInput:focus {
  outline: none !important;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
}
.cprInput::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #cacaca;
}

.cprBtn {
  background: #03abab;
  border-radius: 5px;
  border: 1px solid #03abab;
  width: 120px;
  height: 48px;
  cursor: pointer;
}
.cprBtnText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.cprEntry1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.frameParent48 {
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  flex-direction: row;
  padding: 8px 16px;
  align-items: center;
  color: #31b43e;
}

.abcabccomParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.checkWrapper2 {
}
.closeIcon {
  color: #f04438;
  font-size: 1.2rem;
}
.checkIcon {
  color: #31b43e;
  font-size: 1.2rem;
}

.account-balances {
  .block {
    margin-bottom: 30px;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      display: inline-block;
      margin-bottom: 16px;
    }

    .tx-table {
      .table-row {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px solid #ededed;
      }
    }
  }
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      margin: 0;

      & * {
        font-weight: 600;
        font-size: 13px;
        color: #666666;
      }

      &::before {
        display: none;
      }

      :global(.ant-tabs-ink-bar) {
        display: none;
      }

      :global(.ant-tabs-tab-active) {
        background: #03abab;
        & > * {
          color: #ffffff !important;
        }
      }

      :global(.ant-tabs-nav-list) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        overflow: hidden;
        border-radius: 8px 8px 0px 0px;

        & > :global(.ant-tabs-tab) {
          margin: 0;

          &:first-of-type {
            border-top-left-radius: 8px;
            border-left: 1px solid #ededed;
          }

          &:nth-last-of-type(2) {
            border-top-right-radius: 8px;
          }

          border-top: 1px solid #ededed;

          border-right: 1px solid #ededed;

          &:global(.ant-tabs-tab-active) {
            border: none;
          }
        }

        & > * {
          & > * {
            width: 100%;
            text-align: center;
          }
        }

        &::after {
          content: none;
        }
      }
    }

    :global(.ant-tabs-content) {
      border: 1px solid #03abab;
      border-radius: 0px 0px 8px 8px;
      overflow: hidden;
    }
  }
}
