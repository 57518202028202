.main {
  background: #fbfbfb;
  border-top: 1px solid #ededed;
  padding: 32px 20px;
  margin-top: -18px;
  position: relative;
  box-shadow: 0 4px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.table {
  width: 100%;
  margin-top: 10px;
}
.tr_td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
  padding-bottom: 10px;
}
.info {
  display: flex;
  gap: 2px;
}
.td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  vertical-align: baseline;
  padding-top: 10px;
}
.col1 {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 36px;
  display: flex;
  justify-items: center;
  align-items: center;
  max-width: 57px;
  justify-content: center;
  padding: 4px 4px;
}
.col1 span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #31b43e;
}
.icon {
  font-size: 12px;
  color: #31b43e;
}
.col2,
.col3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.each_class {
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  gap: 3px;
  color: #666666;
}
.cross {
  color: #f04438;
  font-size: 20px;
}
.sign {
  color: #31b43e;
  font-size: 20px;
}
.danger_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #cf352a !important;
}
.danger_icon {
  color: #cf352a;
}

.second_part {
  position: relative;
}
