@import '../../style/varible';

.main {
  margin-left: auto;
  margin-right: auto;
  z-index: 12;
  position: relative;
  margin-bottom: 100px;
}

.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-top: 65px;
  color: #111111;
 
}
.title3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
   
  color: #111111;
  margin-left: -8px;
}

.subTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #111111;
  margin-top: 50px;
}

.subHeader{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 90px;
  color: #111111;
  margin-left: -12px;

}
.animationText{
  white-space: 'pre-line';
  height: 100px;
  display:block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
 
  color: #03ABAB;

}
.divider {
  width: 570px;
  height: 1px;
  background: #EDEDED;
  margin-top: 55px;
}

.touch {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #111111;
  margin-top: 23px;
}

.subTouch {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #111111;
  margin-top: 4px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 16px;
  gap: 12px;

  input {
    height: 48px;
    max-width: unset !important;
    padding-top: 12 !important;
    padding-bottom: 12 !important;
    height: 48 !important;
    background: '#FFFFF' !important;
    border: '1px solid #E2E2E2' !important;
    box-shadow: '0px 1px 2px rgba(16, 24, 40, 0.05)' !important;
    border-radius: 5 !important;
  }
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 8px;
  max-width: 162px;
  height: 48px;
  background: #03ABAB;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  border: none;

  cursor: pointer;
  &:hover {
    // color:rgb(240, 240, 240);
    color: #FFFFFF !important;
    background: #029797;
  }
  &:disabled {
    // color:rgb(240, 240, 240);
    color: #FFFFFF;
    background: #029797;
    opacity: 0.5;
  }
}

@media (min-width: $screen-lg-min) {
  .title {
    font-size: 72px;
    line-height: 90px;

    margin-top: 93px;
  }

  .subTitle {
    font-size: 20px;
    line-height: 30px;
  }

  .touch {
    font-size: 20px;
    line-height: 30px;
  }

  .inputWrapper {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    input {
      max-width: 300px !important;
      padding-top: 12 !important;
      padding-bottom: 12 !important;
    }
  }


}
.subTitle_mobile{
  display: none;
}

@media (max-width:450px ) {
  .subTitle {
   display: none !important;  
  }
  .subHeader,.animationText{
    font-size: 36px;

  }
  .animationText{
    margin-top: 10px;
  }

  .subTitle_mobile{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px; 
  color: #111111;
  margin-top: 50px;
    display: inline;
  }
}