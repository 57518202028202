.footer {
  // height: 56px;
  // margin-bottom: 40px;
  margin-bottom: 20px;
  margin-top: 40px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 12px;
  justify-content: space-between;
 
  width: 90% !important;

  :global(.ant-typography-secondary) {
    color: #666666;
  }
}

.row {
  display: flex;
  gap: 6px;
  align-items: center;

  :global(.ant-typography) {
    font-size: 16px;
  }
}