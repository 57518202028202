.heimdallRwaIcon,
.layer1Icon {
  position: relative;
  flex-shrink: 0;
}
.layer1Icon {
  width: 23.49px;
  height: 35.86px;
  overflow: hidden;
}
.heimdallRwaIcon {
  width: 91.62px;
  height: 13.43px;
}
.layer1Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  height: 70px;
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.frameChild,
.logOutIcon {
  position: relative;
  flex-shrink: 0;
}
.frameChild {
  background-color: #111;
  width: 192px;
  height: 1px;
  opacity: 0.05;
}
.logOutIcon {
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.logOut {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.logOutParent,
.rectangleParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.logOutParent {
  border-radius: 5px;
  background-color: #e2f2f2;
  width: 192px;
  height: 44px;
  flex-shrink: 0;
  flex-direction: row;
  padding: 0 12px;
  box-sizing: border-box;
  gap: 12px;
}
.rectangleParent {
  position: absolute;
  top: 835px;
  left: 24px;
  flex-direction: column;
  gap: 8px;
}
.celsius {
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.gridParent {
  border-radius: 5px;
  background-color: #037c7c;
  width: 192px;
  height: 44px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0 12px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  color: #fff;
}
.celsiusParent,
.frameParent,
.rectangleGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  gap: 4px;
  font-size: 16px;
}
.celsiusParent,
.rectangleGroup {
  gap: 12px;
}
.rectangleGroup {
  position: absolute;
  top: 72px;
  left: 24px;
  justify-content: center;
  gap: 28px;
  font-size: 12px;
}
.text {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.textParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameWrapper {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  width: 303px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  align-items: flex-start;
  justify-content: center;
}
.groupIcon {
  position: relative;
  width: 52px;
  height: 52px;
  flex-shrink: 0;
}
.abcabccom {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  text-decoration: none;
}
.chevronDownIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}
.abcabccomParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.adminLevel1 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #666;
}
.groupParent {
  justify-content: flex-start;
  gap: 8px;
  color: #111;
}
.dashboardParent,
.frameDiv,
.groupParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameDiv {
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  color: #666;
}
.dashboardParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 1200px;
  height: 70px;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between;
}
.adminLevel11 {
  position: absolute;
  top: 90px;
  left: 20px;
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
}
.groupChild,
.rectangleDiv {
  position: absolute;
  top: 152px;
  left: 889px;
  background-color: #e2e2e2;
  width: 1px;
  height: 2791px;
}
.groupChild {
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 270px;
  height: 202px;
}
.onlyForLevel {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #666;
}
.level1AdminParent {
  position: absolute;
  top: 13px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}
.updateCelsiusAccount {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
  font-weight: 600;
}
.ellipseIcon,
.frameChild1 {
  position: relative;
  flex-shrink: 0;
}
.frameChild1 {
  background-color: #111;
  width: 250px;
  height: 1px;
  opacity: 0.05;
}
.ellipseIcon {
  width: 5px;
  height: 5px;
}
.accountAcquired {
  position: relative;
  line-height: 18px;
  font-weight: 600;
}
.frameWrapper1 {
  border-radius: 36px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  height: 28px;
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
}
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  background-color: #03abab;
  width: 48px;
  height: 24px;
}
.groupInner {
  position: absolute;
  top: 3px;
  left: 27px;
  width: 18px;
  height: 18px;
}
.groupDiv {
  position: relative;
  width: 48px;
  height: 24px;
  flex-shrink: 0;
}
.groupChild1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  width: 48px;
  height: 24px;
}
.groupChild2 {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
}
.frameParent3 {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #f04438;
}
.rectangleParent1 {
  position: absolute;
  top: 24px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 12px;
  color: #31b43e;
}
.updateCelsiusAccountStatusParent {
  position: absolute;
  top: 71px;
  left: 10px;
  width: 250px;
  height: 118px;
  font-size: 14px;
}
.rectangleContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
  height: 202px;
}
.groupWrapper,
.setting {
  position: absolute;
  left: 910px;
}
.groupWrapper {
  top: 202px;
  width: 270px;
  height: 202px;
  font-size: 16px;
}
.setting {
  top: 152px;
  line-height: 30px;
  font-weight: 600;
  color: #000;
}
.copyright2023 {
  position: absolute;
  top: 20px;
  left: 0;
  line-height: 20px;
}
.heimdallRwa {
  position: relative;
  line-height: 20px;
}
.heimdallRwaParent {
  position: absolute;
  top: 20px;
  left: 771px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  text-align: right;
  color: #111;
}
.groupChild3 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #111;
  width: 1160px;
  height: 1px;
  opacity: 0.05;
}
.copyright2023ByHeimdallRParent {
  position: absolute;
  top: 4481px;
  left: 20px;
  width: 1160px;
  height: 40px;
  font-size: 14px;
  color: #666;
}
.groupChild4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 270px;
  height: 194px;
}
.level2AdminParent {
  position: absolute;
  top: 12px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}
.updateApplicationStatus {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.xIcon {
  position: relative;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameParent5,
.xWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.xWrapper {
  border-radius: 30px;
  background-color: #f04438;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  padding: 0 6px;
  box-sizing: border-box;
  justify-content: center;
}
.frameParent5 {
  justify-content: flex-start;
  gap: 6px;
}
.groupChild5 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  background-color: #03abab;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  width: 48px;
  height: 24px;
}
.groupChild6 {
  position: absolute;
  top: 3px;
  left: 28px;
  width: 18px;
  height: 18px;
}
.checkWrapper {
  border-radius: 30px;
  background-color: #31b43e;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0 6px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.updateApplicationStatusParent {
  position: absolute;
  top: 70px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
}
.rectangleParent3 {
  position: absolute;
  top: 424px;
  left: 910px;
  width: 270px;
  height: 194px;
  font-size: 16px;
}
.groupChild9 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 270px;
  height: 172px;
}
.legalAdminWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.transferAgreementFiledWithParent {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.agreementStatusParent,
.rectangleParent8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rectangleParent8 {
  justify-content: flex-start;
  gap: 12px;
  font-size: 11px;
}
.agreementStatusParent {
  justify-content: center;
  gap: 6px;
}
.badge,
.badgeParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.badge {
  border-radius: 36px;
  background-color: #edf3c8;
  height: 28px;
  padding: 10px 8px;
  box-sizing: border-box;
  justify-content: center;
}
.badgeParent {
  justify-content: flex-start;
  gap: 26px;
  font-size: 11px;
  color: #98a933;
}
.frameParent8,
.frameParent9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent9 {
  gap: 8px;
  font-size: 14px;
}
.frameParent8 {
  position: absolute;
  top: 12px;
  left: 10px;
  gap: 12px;
}
.rectangleParent7 {
  position: absolute;
  top: 638px;
  left: 910px;
  width: 270px;
  height: 172px;
  font-size: 16px;
}
.assignment {
  position: relative;
  line-height: 30px;
  font-weight: 600;
}
.thisClaimAssigned {
  line-height: 20px;
}
.span {
  font-size: 16px;
  line-height: 24px;
}
.com {
  line-height: 20px;
  font-weight: 500;
  color: #03abab;
}
.thisClaimAssignedContainer {
  position: relative;
  font-size: 14px;
}
.assignmentParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.frameWrapper4,
.groupChild12,
.groupChild13 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 850px;
}
.frameWrapper4 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  align-items: flex-start;
  justify-content: flex-start;
  color: #111;
}
.groupChild12,
.groupChild13 {
  position: absolute;
  top: 0;
  left: 0;
  height: 552px;
}
.groupChild13 {
  border-radius: 8px 8px 0 0;
  background-color: #13a1a1;
  border: 1px solid #ededed;
  height: 60px;
}
.transferAgreement {
  position: absolute;
  top: 16px;
  left: 20px;
  line-height: 30px;
  font-weight: 600;
}
.badge1,
.labelParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.badge1 {
  border-radius: 36px;
  background-color: #edf3c8;
  height: 28px;
  padding: 10px 12px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 12px;
  color: #98a933;
}
.labelParent {
  width: 810px;
  justify-content: space-between;
}
.frameChild8 {
  position: relative;
  background-color: #ededed;
  width: 810px;
  height: 1px;
  flex-shrink: 0;
}
.frameWrapper5 {
  border-radius: 36px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  height: 28px;
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #31b43e;
}
.text1 {
  font-size: 16px;
}
.text1,
.text3 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.eyeParent,
.frameParent11,
.frameParent12,
.textGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.textGroup {
  justify-content: flex-start;
  font-size: 16px;
}
.eyeParent,
.frameParent11,
.frameParent12 {
  justify-content: center;
}
.frameParent11,
.frameParent12 {
  justify-content: flex-start;
  gap: 26px;
  color: #03abab;
}
.frameParent11 {
  position: absolute;
  top: 80px;
  left: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  font-size: 14px;
  color: #111;
}
.rectangleParent10 {
  position: relative;
  width: 850px;
  height: 552px;
  flex-shrink: 0;
  color: #fff;
}
.groupChild14 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 850px;
  height: 352px;
}
.groupChild16 {
  position: absolute;
  top: 18px;
  left: 806px;
  width: 24px;
  height: 24px;
}
.labelParent7 {
  position: absolute;
  top: 90px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.individual {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: #111;
}
.labelParent10,
.labelParent11,
.labelParent12,
.labelParent8,
.labelParent9 {
  position: absolute;
  top: 90px;
  left: 297px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.labelParent10,
.labelParent11,
.labelParent12,
.labelParent9 {
  left: 573px;
}
.labelParent10,
.labelParent11,
.labelParent12 {
  top: 164px;
  left: 20px;
}
.labelParent11,
.labelParent12 {
  top: 238px;
  left: 297px;
}
.labelParent12 {
  left: 573px;
}
.sMiamiAve {
  margin: 0;
}
.labelParent13 {
  position: absolute;
  top: 238px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.frameWrapper6 {
  border-radius: 36px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #cf352a;
}
.labelParent14,
.labelParent15 {
  position: absolute;
  top: 164px;
  left: 297px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.labelParent15 {
  left: 573px;
}
.rectangleParent11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 850px;
  height: 352px;
}
.claimGeneralInformation {
  position: relative;
  width: 850px;
  height: 352px;
  flex-shrink: 0;
  font-size: 14px;
}
.groupChild17,
.lineDiv {
  position: absolute;
  box-sizing: border-box;
}
.groupChild17 {
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f2f4f7;
  width: 850px;
  height: 356px;
}
.lineDiv {
  top: 253.5px;
  left: -0.5px;
  border-top: 1px solid #ededed;
  width: 851px;
  height: 1px;
}
.claimInformation {
  position: absolute;
  top: 16px;
  left: 20px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
}
.badge2,
.frameWrapper7,
.pendingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.badge2 {
  border-radius: 36px;
  background-color: #ffe9d4;
  height: 28px;
  flex-shrink: 0;
  padding: 10px 12px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 12px;
  color: #ef7f18;
}
.frameWrapper7,
.pendingWrapper {
  justify-content: flex-start;
}
.frameWrapper7 {
  border-radius: 36px;
  background-color: #bcbcbc;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  height: 28px;
  flex-shrink: 0;
  padding: 10px 12px;
  justify-content: center;
  font-size: 12px;
  color: #fff;
}
.checkIcon2 {
  position: relative;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  overflow: hidden;
}
.checkFrame,
.frameParent13,
.labelParent19 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkFrame {
  border-radius: 30px;
  background-color: #31b43e;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  padding: 0 6px;
  box-sizing: border-box;
  justify-content: center;
}
.frameParent13,
.labelParent19 {
  justify-content: flex-start;
  gap: 8px;
}
.labelParent19 {
  position: absolute;
  top: 172px;
  left: 20px;
  flex-direction: column;
  align-items: flex-start;
}
.div1 {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: #13a1a1;
}
.labelParent20,
.labelParent21,
.labelParent22,
.labelParent23,
.labelParent24 {
  position: absolute;
  top: 278px;
  left: 297px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.labelParent21,
.labelParent22,
.labelParent23,
.labelParent24 {
  left: 573px;
}
.labelParent22,
.labelParent23,
.labelParent24 {
  left: 20px;
}
.labelParent23,
.labelParent24 {
  top: 172px;
  left: 297px;
  gap: 4px;
}
.labelParent24 {
  left: 573px;
  gap: 8px;
}
.rectangleParent12 {
  position: absolute;
  top: 0;
  left: 0;
  width: 850px;
  height: 356px;
}
.claimGeneralInformation2 {
  position: relative;
  width: 850px;
  height: 355px;
  flex-shrink: 0;
  font-size: 14px;
}
.groupChild20 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 850px;
  height: 1738px;
}
.balancesParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 719px;
}
.frameChild19,
.frameChild20 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  width: 810px;
  height: 240px;
}
.frameChild20 {
  top: 44px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #03abab;
  height: 196px;
}
.quantityContainer,
.quantityFrame,
.quantityWrapper,
.quantityWrapper1,
.quantityWrapper2 {
  position: absolute;
  top: 64px;
  left: 20px;
  width: 120px;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.quantityContainer,
.quantityFrame,
.quantityWrapper1,
.quantityWrapper2 {
  left: 140px;
  width: 201px;
}
.quantityFrame,
.quantityWrapper1,
.quantityWrapper2 {
  left: 341px;
  width: 120px;
}
.quantityWrapper1,
.quantityWrapper2 {
  left: 461px;
  width: 200px;
}
.quantityWrapper2 {
  left: 638px;
  width: 120px;
}
.coinbnbIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.bnbParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.coinbnbIcon1 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: none;
}
.bnbContainer,
.bnbGroup,
.bnbParent1 {
  position: absolute;
  top: 0;
  left: 120px;
  width: 201px;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.bnbContainer,
.bnbParent1 {
  left: 321px;
  width: 120px;
}
.bnbParent1 {
  left: 441px;
  width: 200px;
}
.checkIcon3 {
  position: relative;
  width: 9.6px;
  height: 9.6px;
  flex-shrink: 0;
  overflow: hidden;
}
.checkWrapper1 {
  border-radius: 24px;
  background-color: #31b43e;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  padding: 0 4.800000190734863px;
  box-sizing: border-box;
  justify-content: center;
}
.checkWrapper1,
.frameParent16,
.frameParent17 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameParent17 {
  width: 120px;
  flex-shrink: 0;
  justify-content: space-between;
}
.frameParent16 {
  position: absolute;
  top: 2px;
  left: 618px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  padding: 8px 16px;
  justify-content: flex-start;
  gap: 8px;
  color: #31b43e;
}
.instanceParent {
  position: absolute;
  top: 106px;
  left: 20px;
  width: 770px;
  height: 40px;
  font-size: 14px;
}
.bnbParent3,
.bnbParent4,
.bnbParent5 {
  position: absolute;
  top: 0;
  left: 120px;
  width: 201px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.bnbParent4,
.bnbParent5 {
  left: 321px;
  width: 120px;
}
.bnbParent5 {
  left: 441px;
  width: 200px;
}
.instanceGroup {
  position: absolute;
  top: 170px;
  left: 20px;
  width: 770px;
  height: 40px;
  font-size: 14px;
}
.earnAccount {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 203px;
  flex-shrink: 0;
}
.earnAccountWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 0 0 0;
  background-color: #03abab;
  width: 203px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
.borrowAccount,
.custodyAccount,
.withheldAccount {
  position: absolute;
  top: 10px;
  left: 203px;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
  color: #666;
  text-align: center;
  display: inline-block;
  width: 202px;
}
.borrowAccount,
.withheldAccount {
  left: 406px;
  width: 182px;
}
.borrowAccount {
  left: 608px;
  width: 202px;
}
.frameChild21,
.frameChild22 {
  top: -0.5px;
  border-right: 1px solid #ededed;
  width: 1px;
  height: 45px;
}
.frameChild21 {
  position: absolute;
  left: 202.5px;
  box-sizing: border-box;
}
.frameChild22 {
  left: 404.5px;
}
.frameChild22,
.frameChild23,
.frameChild24 {
  position: absolute;
  box-sizing: border-box;
}
.frameChild23 {
  top: -0.5px;
  left: 607.5px;
  border-right: 1px solid #ededed;
  width: 1px;
  height: 45px;
}
.frameChild24 {
  top: 157.5px;
  left: 19.5px;
  border-top: 1px solid #ededed;
  width: 771px;
  height: 1px;
}
.rectangleParent14 {
  position: relative;
  width: 810px;
  height: 240px;
  flex-shrink: 0;
  font-size: 12px;
}
.frameParent15 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  font-size: 16px;
  color: #111;
}
.txHistory {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.frameChild25,
.frameChild26 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  width: 405px;
  height: 124px;
}
.frameChild26 {
  top: 44px;
  border-radius: 0 0 8px 8px;
  border: 2px solid #03abab;
  width: 810px;
  height: 398px;
}
.frameParent23 {
  position: absolute;
  top: 53px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  color: #03abab;
}
.custodyTxPdf {
  position: absolute;
  top: 7px;
  left: 0;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
}
.quantityWrapper3 {
  width: 120px;
  flex-direction: row;
  padding: 10px 0;
  box-sizing: border-box;
  align-items: flex-start;
}
.frameParent24,
.instanceContainer,
.quantityWrapper3 {
  display: flex;
  justify-content: flex-start;
}
.frameParent24 {
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  flex-direction: row;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #31b43e;
}
.instanceContainer {
  position: absolute;
  top: 0;
  left: 618px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  font-size: 12px;
}
.frameParent22,
.frameParent27 {
  position: absolute;
  top: 234px;
  left: 20px;
  width: 770px;
  height: 80px;
  font-size: 14px;
}
.frameParent27 {
  top: 342px;
}
.frameChild28,
.frameChild29 {
  position: absolute;
  top: 221.5px;
  left: 1.5px;
  border-top: 1px solid #ededed;
  box-sizing: border-box;
  width: 807px;
  height: 1px;
}
.frameChild29 {
  top: 329.5px;
}
.bnbParent8,
.bnbParent9,
.quantityWrapper8,
.quantityWrapper9 {
  position: absolute;
  top: 64px;
  left: 461px;
  width: 120px;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.bnbParent8,
.bnbParent9,
.quantityWrapper9 {
  left: 209px;
}
.bnbParent8,
.bnbParent9 {
  top: 0;
  left: 441px;
  align-items: center;
  gap: 8px;
}
.bnbParent9 {
  left: 189px;
}
.frameChild30 {
  position: absolute;
  top: 0;
  left: 204px;
  border-radius: 0 8px 0 0;
  background-color: #13a1a1;
  width: 201px;
  height: 44px;
}
.custodyAccount1,
.earnAccount1 {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 13px;
  line-height: 24px;
  font-weight: 600;
  color: #666;
  text-align: center;
  display: inline-block;
  width: 204px;
}
.custodyAccount1 {
  left: 204px;
  color: #fff;
  width: 201px;
}
.rectangleParent15 {
  position: relative;
  width: 810px;
  height: 442px;
  flex-shrink: 0;
  font-size: 12px;
  color: #111;
}
.txHistoryParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.frameParent40 {
  position: relative;
  width: 770px;
  height: 80px;
  flex-shrink: 0;
}
.groupChild21,
.groupContainer {
  background-color: #fff;
  border: 1px solid #ededed;
}
.groupContainer {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #111;
}
.groupChild21 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 8px 8px 8px;
  box-sizing: border-box;
  width: 810px;
  height: 168px;
}
.labelWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.trashParent {
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
}
.frameParent45,
.labelParent25,
.trashParent {
  display: flex;
  align-items: flex-start;
}
.frameParent45 {
  width: 770px;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  color: #111;
}
.labelParent25 {
  position: absolute;
  top: 104px;
  left: 20px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}
.groupChild22 {
  position: absolute;
  top: 83.5px;
  left: 19.5px;
  border-top: 1px solid #ededed;
  box-sizing: border-box;
  width: 771px;
  height: 1px;
}
.labelParent26 {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.rectangleParent16 {
  position: relative;
  width: 810px;
  height: 168px;
  flex-shrink: 0;
  font-size: 12px;
  color: #666;
}
.chevronDownIcon8,
.label31 {
  position: relative;
  display: none;
}
.label31 {
  line-height: 20px;
  font-weight: 500;
}
.chevronDownIcon8 {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.textParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 133px;
}
.input,
.inputInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inputInner {
  align-self: stretch;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #e2e2e2;
  padding: 12px 16px;
  justify-content: center;
  font-size: 16px;
  color: #cacaca;
}
.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 678px;
  justify-content: flex-start;
  gap: 6px;
}
.inputWrapper {
  position: relative;
  width: 678px;
  height: 48px;
  flex-shrink: 0;
  font-size: 14px;
  color: #111;
}
.groupChild23 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 8px 8px 8px;
  background-color: #fff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  width: 810px;
  height: 196px;
}
.fifileTextParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #03abab;
}
.rectangleParent17 {
  position: relative;
  width: 810px;
  height: 196px;
  flex-shrink: 0;
  font-size: 12px;
  color: #666;
}
.input1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 810px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.iconoutlineupload {
  position: absolute;
  top: 14px;
  left: 770px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.inputParent {
  position: relative;
  width: 810px;
  height: 48px;
  flex-shrink: 0;
  font-size: 14px;
  color: #111;
}
.frameParent14 {
  position: absolute;
  top: 90px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
.rectangleParent13 {
  position: absolute;
  top: 0;
  left: 0;
  width: 850px;
  height: 1738px;
}
.claimGeneralInformation3 {
  position: relative;
  width: 850px;
  height: 1738px;
  flex-shrink: 0;
  font-size: 18px;
  color: #000;
}
.frameWrapper10,
.groupChild26 {
  background-color: #fff;
  box-sizing: border-box;
}
.groupChild26 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid #f2f4f7;
  width: 850px;
  height: 504px;
}
.frameWrapper10 {
  border-radius: 36px;
  border: 1px solid #e2e2e2;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #31b43e;
}
.labelParent32,
.labelParent33,
.labelParent34,
.labelParent35 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.labelParent33,
.labelParent34,
.labelParent35 {
  top: 86px;
}
.labelParent34,
.labelParent35 {
  top: 0;
  left: 277px;
  gap: 16px;
}
.labelParent35 {
  top: 86px;
}
.frameParent48,
.labelParent36 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
.frameParent48 {
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #ededed;
  flex-direction: row;
  padding: 8px 16px;
  align-items: center;
  color: #31b43e;
}
.labelParent36 {
  position: absolute;
  top: 0;
  left: 553px;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent47 {
  position: absolute;
  top: 90px;
  left: 20px;
  width: 705px;
  height: 142px;
  font-size: 14px;
  color: #666;
}
.rectangleParent18 {
  position: absolute;
  top: 0;
  left: 0;
  width: 850px;
  height: 504px;
}
.frameChild34 {
  position: relative;
  border-top: 1px solid #ededed;
  box-sizing: border-box;
  width: 811px;
  height: 1px;
  flex-shrink: 0;
}
.frameParent53 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  color: #03abab;
}
.labelParent40,
.lineParent {
  display: flex;
  align-items: flex-start;
}
.lineParent {
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.labelParent40 {
  width: 533px;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  color: #111;
}
.labelParent38,
.labelParent39 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.labelParent39 {
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
}
.labelParent38 {
  flex-direction: row;
  gap: 218px;
}
.frameChild36 {
  position: relative;
  border-top: 1px solid #ededed;
  box-sizing: border-box;
  width: 851px;
  height: 1px;
  flex-shrink: 0;
}
.frameParent52 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.inputGroup {
  position: relative;
  width: 810px;
  height: 48px;
  flex-shrink: 0;
  color: #111;
}
.frameParent51 {
  position: absolute;
  top: 262px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  font-size: 14px;
  color: #666;
}
.emailFowardVerification {
  position: relative;
  width: 850px;
  height: 504px;
  flex-shrink: 0;
  color: #fff;
}
.groupChild28,
.groupChild29 {
  position: absolute;
  box-sizing: border-box;
}
.groupChild28 {
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f2f4f7;
  width: 850px;
  height: 477px;
}
.groupChild29 {
  top: 388.5px;
  left: -0.5px;
  border-top: 1px solid #ededed;
  width: 851px;
  height: 1px;
}
.input3 {
  position: absolute;
  top: 409px;
  left: 20px;
  width: 810px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  color: #111;
}
.iconoutlineupload2 {
  position: absolute;
  top: 423px;
  left: 790px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.label48 {
  position: absolute;
  top: 200px;
  left: 20px;
  line-height: 20px;
  font-weight: 500;
}
.groupChild30,
.groupChild31 {
  position: absolute;
  top: 183.5px;
  left: -0.5px;
  border-top: 1px solid #ededed;
  box-sizing: border-box;
  width: 851px;
  height: 1px;
}
.groupChild31 {
  top: 267.5px;
  left: 296.5px;
  width: 554px;
}
.labelParent41 {
  position: absolute;
  top: 90px;
  left: 20px;
  flex-direction: column;
  gap: 12px;
}
.individualParent,
.labelParent41,
.labelParent42,
.labelParent43 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.individualParent {
  flex-direction: row;
  gap: 6px;
  color: #111;
}
.labelParent42,
.labelParent43 {
  position: absolute;
  top: 90px;
  left: 297px;
  flex-direction: column;
  gap: 16px;
}
.labelParent43 {
  left: 573px;
  gap: 8px;
}
.rectangleParent19 {
  position: absolute;
  top: 0;
  left: 0;
  width: 850px;
  height: 477px;
  font-size: 14px;
}
.labelParent44,
.labelParent46 {
  position: absolute;
  top: 200px;
  left: 297px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.labelParent46 {
  top: 287px;
}
.emailFowardVerification1 {
  position: relative;
  width: 850px;
  height: 477px;
  flex-shrink: 0;
  font-size: 12px;
}
.frameParent10 {
  position: absolute;
  top: 152px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  color: #666;
}
.frameContainer,
.primaryButton {
  position: absolute;
  top: 0;
  background-color: #f1f7f7;
  width: 1200px;
  height: 4541px;
  overflow: hidden;
  font-size: 20px;
  color: #111;
}
.primaryButton {
  top: 2879px;
  left: 970px;
  border-radius: 5px;
  background-color: #03abab;
  width: 120px;
  height: 48px;
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
}
.level01 {
  position: relative;
  background-color: #e2f2f2;
  width: 100%;
  height: 4541px;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #666;
  font-family: Inter;
}

.dot {
  font-size: 20px;
}
.closeIcon {
  color: #f04438;
  font-size: 1.5rem;
}
.checkIcon {
  color: #31b43e;
  font-size: 1.5rem;
}

.panel_collapse {
  border: 1px solid #f2f4f7;
  display: flex;
  flex-direction: column;
  gap: 10px;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  :global(.ant-collapse-item) {
    border-bottom: none;
    background: #ffffff;
    border-radius: 8px !important;

    > :global(.ant-collapse-header) {
      background-color: #13a2a2;
      border: 1px solid #ededed;
      border-radius: 8px !important;
      height: 60px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      > :global(.ant-collapse-header-text) {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;

        color: #ffffff;
      }

      > :global(.ant-collapse-expand-icon) {
        height: 24px;
        width: 24px;
        background: white;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-inline-start: 0px !important;

        > span {
          > * {
            fill: #13a1a1;
          }
        }
      }
    }

    > :global(.ant-collapse-content) {
      border-top: none;
      border-radius: 0 0 8px 8px;
      > :global(.ant-collapse-content-box) {
        padding: 0;
      }
    }

    &:global(.ant-collapse-item-active) {
      > :global(.ant-collapse-header) {
        border-radius: 8px 8px 0px 0px !important;
      }
    }
  }
}
