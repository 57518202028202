 
.frameChild1 {
  background-color: #111;
  width: 250px;
  height: 1px;
  opacity: 0.05;
}
.ellipseIcon {
  width: 5px;
  height: 5px;
}
.accountAcquired {
  position: relative;
  line-height: 18px;
  font-weight: 600;
}
.frameWrapper1 {
  border-radius: 36px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  height: 28px;
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
}
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  background-color: #03abab;
  width: 48px;
  height: 24px;
}
.groupInner {
  position: absolute;
  top: 3px;
  left: 27px;
  width: 18px;
  height: 18px;
}
.groupDiv {
  position: relative;
  width: 48px;
  height: 24px;
  flex-shrink: 0;
}
.groupChild1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
  width: 48px;
  height: 24px;
}
.groupChild2 {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
}
.frameParent3 {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #f04438;
}
.rectangleParent1 {
  position: absolute;
  top: 24px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 12px;
  color: #31b43e;
}
.updateCelsiusAccountStatusParent {
  position: absolute;
  top: 71px;
  left: 10px;
  width: 250px;
  height: 118px;
  font-size: 14px;
}
.rectangleContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
  height: 202px;
}
.groupWrapper,
.setting {
  position: absolute;
  left: 910px;
}
.groupWrapper {
  top: 202px;
  width: 270px;
  height: 202px;
  font-size: 16px;
}
.setting {
  top: 152px;
  line-height: 30px;
  font-weight: 600;
  color: #000;
}
.copyright2023 {
  position: absolute;
  top: 20px;
  left: 0;
  line-height: 20px;
}
.heimdallRwa {
  position: relative;
  line-height: 20px;
}
.heimdallRwaParent {
  position: absolute;
  top: 20px;
  left: 771px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  text-align: right;
  color: #111;
}
.groupChild3 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #111;
  width: 1160px;
  height: 1px;
  opacity: 0.05;
}
.copyright2023ByHeimdallRParent {
  position: absolute;
  top: 4481px;
  left: 20px;
  width: 1160px;
  height: 40px;
  font-size: 14px;
  color: #666;
}
.groupChild4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 270px;
  height: 194px;
}
.level2AdminParent {
  position: absolute;
  top: 12px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}
.updateApplicationStatus {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.xIcon {
  position: relative;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameParent5,
.xWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.xWrapper {
  border-radius: 30px;
  background-color: #f04438;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  padding: 0 6px;
  box-sizing: border-box;
  justify-content: center;
}
.frameParent5 {
  justify-content: flex-start;
  gap: 6px;
}
.groupChild5 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 40px;
  background-color: #03abab;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  width: 48px;
  height: 24px;
}
.groupChild6 {
  position: absolute;
  top: 3px;
  left: 28px;
  width: 18px;
  height: 18px;
}
.checkWrapper {
  border-radius: 30px;
  background-color: #31b43e;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 0 6px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.updateApplicationStatusParent {
  position: absolute;
  top: 70px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
}
.rectangleParent3 {
  position: absolute;
  top: 424px;
  left: 910px;
  width: 270px;
  height: 194px;
  font-size: 16px;
}
.groupChild9 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 270px;
  height: 172px;
}
.legalAdminWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.transferAgreementFiledWithParent {
  width: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.agreementStatusParent,
.rectangleParent8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rectangleParent8 {
  justify-content: flex-start;
  gap: 12px;
  font-size: 11px;
}
.agreementStatusParent {
  justify-content: center;
  gap: 6px;
}
.badge,
.badgeParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.badge {
  border-radius: 36px;
  background-color: #edf3c8;
  height: 28px;
  padding: 10px 8px;
  box-sizing: border-box;
  justify-content: center;
}
.badgeParent {
  justify-content: flex-start;
  gap: 26px;
  font-size: 11px;
  color: #98a933;
}
.frameParent8,
.frameParent9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent9 {
  gap: 8px;
  font-size: 14px;
}
.frameParent8 {
  position: absolute;
  top: 12px;
  left: 10px;
  gap: 12px;
}
.rectangleParent7 {
  position: absolute;
  top: 638px;
  left: 910px;
  width: 270px;
  height: 172px;
  font-size: 16px;
}
.assignment {
  position: relative;
  line-height: 30px;
  font-weight: 600;
}
.thisClaimAssigned {
  line-height: 20px;
}
.span {
  font-size: 16px;
  line-height: 24px;
}
.com {
  line-height: 20px;
  font-weight: 500;
  color: #03abab;
}
.thisClaimAssignedContainer {
  position: relative;
  font-size: 14px;
}
.assignmentParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.frameWrapper4,
.groupChild12,
.groupChild13 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f2f4f7;
  box-sizing: border-box;
  width: 850px;
}
.frameWrapper4 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  align-items: flex-start;
  justify-content: flex-start;
  color: #111;
}
.groupChild12,
.groupChild13 {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
}
.groupChild13 {
  border-radius: 8px 8px 0 0;
  background-color: #13a1a1;
  border: 1px solid #ededed;
  height: 60px;
}
.transferAgreement {
  position: absolute;
  top: 16px;
  left: 20px;
  line-height: 30px;
  font-weight: 600;
}
.badge1,
.labelParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.badge1 {
  border-radius: 36px;
  background-color: #edf3c8;
  height: 28px;
  padding: 10px 12px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 12px;
  color: #98a933;
}
.labelParent {
  width: 810px;
  justify-content: space-between;
}
.frameChild8 {
  position: relative;
  background-color: #ededed;
  width: 810px;
  height: 1px;
  flex-shrink: 0;
}
.frameWrapper5 {
  border-radius: 36px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  height: 28px;
  display: flex;
  flex-direction: row;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #31b43e;
}
.text1 {
  font-size: 16px;
}
.text1,
.text3 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
}
.eyeParent,
.frameParent11,
.frameParent12,
.textGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.textGroup {
  justify-content: flex-start;
  font-size: 16px;
}
.eyeParent,
.frameParent11,
.frameParent12 {
  justify-content: center;
}
.frameParent11,
.frameParent12 {
  justify-content: flex-start;
  gap: 26px;
  color: #03abab;
}
.frameParent11 {
  position: absolute;
  top: 80px;
  left: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  font-size: 14px;
  color: #111;
}
.rectangleParent10 {
  position: relative;
  width: 850px;
  height: 592px;
  flex-shrink: 0;
  color: #fff;
}
 
.download{
font-size: 1.5rem;
}
.copy_icon{
    font-size: 1rem;
    cursor: pointer;
    color: #03ABAB;
}